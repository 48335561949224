import React, { useState } from "react";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={4}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 4</H1>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>People take a medicine because they want it to help them. In other words, they want a <strong>benefit</strong> from taking the medicine.</p>
            <p>There is usually some chance that the medicine either will not help someone or could even cause side effects. Side effects are problems caused by the drug. These unwanted results are risks for the medicine. You can define <strong>risk</strong> as the chance that something unwanted will happen.</p>
            <p>It is important to remember that risk is just a higher chance of something happening. It does not mean that anyone who takes this medicine <em>will</em> have these problems.</p>
            <p>Think about a person walking on a sidewalk. There is a chance that the person might fall and hurt himself or herself. Most of the time the chance (risk) of falling is low. However, if the sidewalk is covered with ice, the person's risk of falling is higher. A higher risk does not mean that person will fall—it just means that the chance is greater.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Students are introduced to the concepts of benefit and risk. Most students will not have much trouble understanding the idea of benefits. The idea of risk may be a bit more challenging for students. A common misconception is that risk implies that the negative consequence <em>will</em> occur rather than it just being more likely. Other students may think that risks are only a problem if there is something already "wrong" with a person. Students will continue to think about risk and benefit during the remainder of the lesson.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

